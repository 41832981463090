import axios from "axios";
import React,{  useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader";
import CollegeHeader from "./college/CollegeHeader";
import collegeimg from '../../src/assets/images/default-banner.jpg';
import collegelogo from '../../src/assets/images/default-logo.jpg';
const LIMIT = 8;
function  Home() {
  const [colleges, setColleges] = useState([]);
  const [totalColleges, setTotalColleges] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [successNewsLetterEmail, setSuccessNewsLetterEmail] = useState('');


  const [newsLetterFormData, setNewsLetterFormData] = useState({
    newsletteremail: ''
  });
  const API_URL = process.env.REACT_APP_API_URL;
  
    // useEffect(() => {
    //   fetchData();
    // }, []);
  
    // const fetchData = () => {
    //   setTimeout(() => {
    //   }, 3000);
    //   axios.get('http://localhost:7000/colleges', {
    //     params: {
    //       page: activePage,
    //       size: LIMIT
    //     }
    //   }).then(({data}) => {
    //     setActivePage(activePage+ 1);
    //     setColleges([...colleges, ...data.records]);
    //     setTotalColleges(data.total)
    //   }).catch(error => {
    //     console.log(error.response);
    //   })
    // }

    const handleChangeNewsLetter = (e) => {
      const { name, value } = e.target;
      setNewsLetterFormData({
        ...newsLetterFormData,
        [name]: value
      });
    };
    const sendNewsLetterEmail = async (e) => { 
      e.preventDefault();
      try {
        const response = await axios.post(API_URL + "frontend/news-letter", newsLetterFormData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setSuccessNewsLetterEmail(response.data.email);
        console.log('Success:', response.data);
        console.log('Success:', response.data.message);
      } catch (error) {
        console.error('Error:', error);
      }
    }

  return (
    <div>
      <h2>Home Page</h2>
      <p>Learn more about us!</p>
      <form onSubmit={sendNewsLetterEmail}>
     
      <label>
        Email:
        <input type="email" name="newsletteremail" value={newsLetterFormData.newsletteremail} onChange={handleChangeNewsLetter} />
      </label>
      <br />
      <button type="submit">Submit</button>
    </form>
    </div>
  );
}

export default Home;