import { useState } from "react";
function CollegeInfoMainTable(props){
console.log(props)
    //const collegeType = Object.keys(props.collegeData.colleges.college_type.college_type_name)
   //const [collegeType, setCollegeType] = useState(props.collegeData.colleges.college_type.college_type_name); 
    return (
        <>
        <table class="table table-bordered table-striped">
            <tbody>
                
                <tr>
                    <td class="TD1">College Code</td>
                    <td class="TD2">{props.collegeData.colleges.college_code}</td>
                </tr>
                <tr>
                    <td class="TD1">College Name</td>
                    <td class="TD2">{props.collegeData.colleges.college_name}</td>
                </tr>
                <tr>
                    <td class="TD1">Other Name</td>
                    <td class="TD2">{props.collegeData.colleges.other_name}</td>
                </tr>
                <tr>
                    <td class="TD1">Established</td>
                    <td class="TD2">{props.collegeData.colleges.eshtablish}</td>
                </tr>
                <tr>
                    <td class="TD1">Type</td>
                    <td class="TD2">
                        {props.collegeData.colleges.college_type?.college_type_name}
                        </td>
                </tr>
                <tr>
                    <td class="TD1">Undertaking</td>
                    <td class="TD2">
                        {props.collegeData.colleges.undertaking?.undertaking_name}
                        </td>
                </tr>
                <tr>
                    <td class="TD1">Principal / T.I.C</td>
                    <td class="TD2">{props.collegeData.colleges.principal_name}</td>
                </tr>
                <tr>
                    <td class="TD1">Landmark</td>
                    <td class="TD2">{props.collegeData.colleges.landmark}</td>
                </tr>
                <tr>
                    <td class="TD1">City</td>
                    <td class="TD2">
                        {props.collegeData.colleges.city?.city_name}
                        </td>
                </tr>
                <tr>
                    <td class="TD1">State</td>
                    <td class="TD2">
                        {props.collegeData.colleges.state?.state_name}

                    </td>
                </tr>
                <tr>
                    <td class="TD1">Country</td>
                    <td class="TD2">
                        {props.collegeData.colleges.country?.country_name}
                        </td>
                </tr>
                <tr>
                    <td class="TD1">Course Type</td>
                    <td class="TD2">
                        {props.collegeData.colleges.course_type_value}
                        </td>
                </tr>
                <tr>
                    <td class="TD1">Courses</td>
                    <td class="TD2">
                        {props.collegeData.colleges.course_value}
                        </td>
                </tr>
                <tr>
                    <td class="TD1">Affiliation</td>
                    <td class="TD2"><a href="" title="list of colleges under <?php echo strtolower ($universityName);?> (<?php echo strtolower ($universityAbbreviation);?>)">{props.collegeData.colleges.affiliation?.university_name}  {props.collegeData.colleges.affiliation?.short_name?(<span style={{color:"red"}}>({ props.collegeData.colleges.affiliation?.short_name})</span>):null}</a></td>
                </tr>
                <tr>
                    <td class="TD1">Accreditation / Recognition</td>
                    <td class="TD2">{props.collegeData.colleges.accreditation}</td>
                </tr>
            </tbody>
        </table>
        </>
    );
}
export default CollegeInfoMainTable;