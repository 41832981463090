function CollegePageMenu(){
    return (
        <>
        <section class="bg-light">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container-fluid">
                <a class="navbar-brand d-md-none d-lg-none" href="#">College Menu</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse c_page_menu" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item me-5">
                        <a class="nav-link active" aria-current="page" href="#"><i class="fas fa-solid fa-building"></i> OVERVIEW</a>
                        </li>
                        <li class="nav-item me-5">
                        <a class="nav-link active" aria-current="page" href="#"><i class="fas fa-book-reader"></i> COURSES OFFERED</a>
                        </li>
                        <li class="nav-item me-5">
                        <a class="nav-link active" aria-current="page" href="#"><i class="fas fa-desktop"></i> ADMISSION 2025 <sup><span class="badge text-bg-danger faa-flash animated">New</span></sup></a>
                        </li>
                        <li class="nav-item me-5">
                        <a class="nav-link active" aria-current="page" href="#"><i class="fas fa-edit"></i> APPLY NOW 2025 <sup><span class="badge text-bg-danger faa-flash animated">New</span></sup></a>
                        </li>
                        <li class="nav-item me-5">
                        <a class="nav-link active" aria-current="page" href="#"><i class="fas fa-list-ol"></i> MERIT / ADMISSION LIST 2025 <sup><span class="badge text-bg-danger faa-flash animated">New</span></sup></a>
                        </li>
                        <li class="nav-item me-5">
                        <a class="nav-link active" aria-current="page" href="#"><i class="fas fa-globe"></i> CONTACT</a>
                        </li>
                    </ul>
                </div>
            </div>
            </nav>
        </section>
        </>
    );
}

export default CollegePageMenu;