import CollegePageHeader from "./CollegePageHeader";
import CollegePageMenu from "./CollegePageMenu";
import CollegeInfoMainTable from "./CollegeInfoMainTable";
import CollegePageAdmissionLink from "./CollegePageAdmissionLink";
import CollegePageAdmissionNoticeBoard from "./CollegePageAdmissionNoticeBoard";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//const API_URL = process.env.REACT_APP_API_URL;

const API_URL = 'https://college-api-7ck4.onrender.com/';

function CollegeDetail() {
    const params = useParams();
    const [colleges, setColleges] = useState([]);
    const [collegeNotice, setCollegeNotice] = useState([]);

    useEffect(() => {
        fetchCollegeData();
        //fetchCollegeNotice();
    }, []);
    
    const fetchCollegeData = () => {
        setTimeout(() => {
        }, 3000);
        axios.get(API_URL + `frontend/college/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
           // setColleges(data.records);
            fetchCourseType(data.records);
            fetchCourse(data.records);
            fetchCollegeNotice(data.records._id);
            //  console.log(colleges);
        }).catch(error => {
            console.log(error.response);
        })
    }

    const fetchCourseType = async (collegeRecord) => {
        try {
            const response = await axios.get(API_URL + 'frontend/course-type');

            let courseTypeOption = response.data.records?.map(item => {
                let properties = {
                    "value": item._id,
                    "label": item.course_type_name
                };
                return properties;
            });
            if (collegeRecord.course_type) {
                const courseTypeArray = collegeRecord.course_type?.split(',');
                const selectedcourseTypeOptionValue = courseTypeOption.filter(filterByID);
                function filterByID(item) {
                    if (courseTypeArray?.includes(item.value)) {
                        return true;
                    }
                }
                var courseTypeValues = Array.prototype.map.call(selectedcourseTypeOptionValue, function (item) { return item.label; }).join(",");
                console.log(courseTypeValues)
                collegeRecord.course_type_value = courseTypeValues;

                setColleges(collegeRecord);
            }
            

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchCourse = async (collegeRecord) => {
        try {
            const response = await axios.get(API_URL + 'frontend/course');

            let courseOption = response.data.records?.map(item => {
                let properties = {
                    "value": item._id,
                    "label": item.course_name,
                    "short_name": item.short_name
                };
                return properties;
            });
            if (collegeRecord.course) {
                const courseArray = collegeRecord.course?.split(',');
                const selectedcourseOptionValue = courseOption.filter(filterByID);
                function filterByID(item) {
                    if (courseArray?.includes(item.value)) {
                        return true;
                    }
                }
                var courseValues = Array.prototype.map.call(selectedcourseOptionValue, function (item) { return item.label+'(' +item.short_name+')'; }).join(",");
              
                collegeRecord.course_value = courseValues;

                setColleges(collegeRecord);
            }

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchCollegeNotice = async (college_id) => {
      try {
        const response = await axios.get(API_URL + 'frontend/college-notice', {
              params: {
                id: college_id
              }
        });
        setCollegeNotice(response.data.records);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
  };

    return (
        <>
            <CollegePageHeader collegeData={{ colleges }} />
            <CollegePageMenu />

            <section class="mt-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12">
                                    <h4 class="alert alert-info p-3" style={{ fontFamily: 'Rubik' }}><i class="fas fa-building"></i> {colleges.college_name}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-4">
                                    <CollegeInfoMainTable collegeData={{ colleges }} />
                                </div>
                            </div>

                            <CollegePageAdmissionLink />

                            <div class="row d-none d-sm-block">
                                <div class="col-md-12">
                                    <CollegePageAdmissionNoticeBoard collegeNoticeData={{ collegeNotice }} />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default CollegeDetail;