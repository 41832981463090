import React from 'react';

function About() {
  return (
    <div>
      <h2>About Page</h2>
      <p>Learn more about us!</p>
    </div>
  );
}

export default About;