function Header(){

    return (
      <section class="bg-dark">
      <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 pl-2 pr-2 mt-3">
                <a class="btn btn-warning btn-sm" href="javascript:void(0)" title=""><span class="faa-flash animated"><i class="fa fa-bullhorn"></i> Latest Updates</span></a>
              </div>
              <div class="col-md-12 pl-2 pr-2">
                {/* <hr style={{background:#666}}> */}
              </div>
              <div class="col-md-12 pl-2 pr-2 mb-3">
              google_ads_horizontal_header
              </div>
          </div>
      </div>
  </section>
    );
}
export default Header;